import React from 'react';

// components
import QAOverviewPanel from './QAOverviewPanel';

// interfaces
interface ControlBank {
  controlBankId: string;
  instruments: {
    serialNumber: string;
    controlBankIndex: string;
    cycleCount: string;
    introductionDt: string;
  }[];
  instrumentsCount: string;
  isApproved: boolean;
  approvedBy: null | string;
  approvedDt: string;
  isReserve: boolean;
  createdDt: string;
  updatedDt: string;
}

// mock response
export const apiResponse: ControlBank[] = [
  {
    controlBankId: '37076f14-dae2-1h50-982e-734252e34192',
    instruments: [
      {
        serialNumber: '30000-01-01-00001',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:30:06',
      },
      {
        serialNumber: '30000-02-02-00002',
        controlBankIndex: '2',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
    ],
    instrumentsCount: '2',
    isApproved: false,
    approvedBy: null,
    approvedDt: '',
    isReserve: false,
    createdDt: '02-Aug-2022 08:30:06',
    updatedDt: '02-Aug-2022 08:30:06',
  },
  {
    controlBankId: '37076f14-dae2-4f26-982e-734252e34192',
    instruments: [
      {
        serialNumber: '30000-01-01-00001',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:30:06',
      },
      {
        serialNumber: '30000-02-02-00002',
        controlBankIndex: '2',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
      {
        serialNumber: '30000-03-03-00003',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
      {
        serialNumber: '30000-03-03-00003',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
    ],
    instrumentsCount: '4',
    isApproved: false,
    approvedBy: null,
    approvedDt: '',
    isReserve: false,
    createdDt: '02-Aug-2022 08:30:06',
    updatedDt: '02-Aug-2022 08:30:06',
  },
  {
    controlBankId: '37076f14-dae3-9w18-982e-734252e34192',
    instruments: [
      {
        serialNumber: '30000-01-01-00001',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:30:06',
      },
      {
        serialNumber: '30000-02-02-00002',
        controlBankIndex: '2',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
      {
        serialNumber: '30000-03-03-00003',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
      {
        serialNumber: '30000-01-01-00001',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:30:06',
      },
      {
        serialNumber: '30000-02-02-00002',
        controlBankIndex: '2',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
      {
        serialNumber: '30000-03-03-00003',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
    ],
    instrumentsCount: '6',
    isApproved: false,
    approvedBy: null,
    approvedDt: '',
    isReserve: false,
    createdDt: '02-Aug-2022 08:30:06',
    updatedDt: '05-Aug-2022 08:30:06',
  },
  {
    controlBankId: '37076f14-dae3-2i83-982e-734252e34192',
    instruments: [
      {
        serialNumber: '30000-01-01-00001',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:30:06',
      },
      {
        serialNumber: '30000-02-02-00002',
        controlBankIndex: '2',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
      {
        serialNumber: '30000-03-03-00003',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
    ],
    instrumentsCount: '3',
    isApproved: false,
    approvedBy: null,
    approvedDt: '',
    isReserve: false,
    createdDt: '02-Aug-2022 08:30:06',
    updatedDt: '06-Aug-2022 08:30:06',
  },
  {
    controlBankId: '37076f14-dae4-1x53-982e-734252e34192',
    instruments: [
      {
        serialNumber: '30000-01-01-00001',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:30:06',
      },
      {
        serialNumber: '30000-02-02-00002',
        controlBankIndex: '2',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
      {
        serialNumber: '30000-03-03-00003',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
      {
        serialNumber: '30000-02-02-00002',
        controlBankIndex: '2',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
      {
        serialNumber: '30000-03-03-00003',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
    ],
    instrumentsCount: '5',
    isApproved: false,
    approvedBy: null,
    approvedDt: '',
    isReserve: false,
    createdDt: '02-Aug-2022 08:30:06',
    updatedDt: '06-Aug-2022 08:30:06',
  },
  {
    controlBankId: '37076f14-dae4-8l12-982e-734252e34192',
    instruments: [
      {
        serialNumber: '30000-01-01-00001',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:30:06',
      },
      {
        serialNumber: '30000-02-02-00002',
        controlBankIndex: '2',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
      {
        serialNumber: '30000-03-03-00003',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
    ],
    instrumentsCount: '3',
    isApproved: false,
    approvedBy: null,
    approvedDt: '',
    isReserve: false,
    createdDt: '02-Aug-2022 08:30:06',
    updatedDt: '08-Aug-2022 08:30:06',
  },
  {
    controlBankId: '37076f14-dae5-5v45-982e-734252e34192',
    instruments: [
      {
        serialNumber: '30000-01-01-00001',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:30:06',
      },
      {
        serialNumber: '30000-02-02-00002',
        controlBankIndex: '2',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
      {
        serialNumber: '30000-03-03-00003',
        controlBankIndex: '3',
        cycleCount: '2',
        introductionDt: '03-Aug-2022 07:57:06',
      },
    ],
    instrumentsCount: '3',
    isApproved: false,
    approvedBy: null,
    approvedDt: '',
    isReserve: false,
    createdDt: '02-Aug-2022 08:30:06',
    updatedDt: '09-Aug-2022 08:30:06',
  },
];

const StartUp = () => {
  return (
    <div data-testid="startUpScreen">
      <QAOverviewPanel apiResponse={apiResponse} />
    </div>
  );
};

export default StartUp;
