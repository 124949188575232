import React from 'react';

// libs
import { Switch, Route } from 'react-router-dom';

// components
import PerformanceTesting from './components/PerformanceTesting';

const path = '/qc-automation/testing-input/performance-testing';

const routes = () => {
  return (
    <Switch>
      <Route path={path} component={PerformanceTesting} />
    </Switch>
  );
};

routes.path = path;
export default routes;
