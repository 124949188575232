import React from 'react';

// libs
import {
  AppBar,
  Box,
  Grid,
  Tab,
  Tabs,
  Fade,
  Breadcrumbs,
  Typography,
  Theme,
  makeStyles,
  Link,
  LinkProps,
} from '@material-ui/core';
import { Route, Routes, Link as RouterLink, useLocation } from 'react-router-dom';

// breadcrumb map
const breadcrumbNameMap: { [key: string]: string } = {
  '/qc-automation': 'Startup',
  '/qc-automation/testing-input': 'Testing Input',
  '/qc-automation/testing-input/functional-testing': 'Functional Testing',
  '/qc-automation/testing-input/instrument-bias': 'Instrument Bias',
  '/qc-automation/testing-input/visual-testing': 'Visual Testing',
  '/qc-automation/testing-input/performance-testing': 'Performance Testing',
  '/qc-automation/testing-input/test-setup': 'Test Set-up',
  '/qc-automation/instrument-output': 'Instrument Output',
  '/qc-automation/instrument-output/instrument-summary': 'Instrument Summary',
  '/qc-automation/instrument-output/search': 'Search',
  '/qc-automation/restricted-access': 'Restricted Access',
  '/qc-automation/restricted-access/superuser': 'Superuser',
  '/qc-automation/restricted-access/qa-signoff': 'QA Sign-off',
};

// interfaces
interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

// styles
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'fixed',
    zIndex: 5,
    top: '4.5em',
    left: '17em',
    right: '0em',
    height: '2.1em',
    '& li, a': { color: 'white' },
    padding: '0.5em',
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    marginBottom: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    paddingLeft: '1.8em',
    paddingRight: '1.8em',
  },
}));

const BreadcrumbsBar = () => {
  const classes = useStyles();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

  return (
    <Box className={classes.container}>
      <Breadcrumbs aria-label="breadcrumb" separator=">">
        {pathnames.map((v, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          return last ? (
            <Typography key={to}>{breadcrumbNameMap[to]}</Typography>
          ) : (
            <LinkRouter underline="hover" to={to} key={to}>
              {breadcrumbNameMap[to]}
            </LinkRouter>
          );
        })}
      </Breadcrumbs>
      <Typography>QC Automation</Typography>
    </Box>
  );
};

export default BreadcrumbsBar;
