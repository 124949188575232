/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
import React from 'react';
import { useDispatch } from 'react-redux';
import { BatchRecord } from 'modules/Pilot/BatchRecord/domain';
import useServer from 'modules/Pilot/BatchRecord/diagnostics/useServer';
import { FetchBatchRecordActions } from '../actions';

export type DebugEvent = {
  data: Record<string, unknown>;
  timestamp: string;
  name: string;
};

export type DebugDto = Record<string, unknown> & {
  state?: { version: number };
  events: {
    item1: string;
    item2: DebugEvent;
  }[];
  batchRecord: BatchRecord;
};

export function useDiagnostics({
  batchRecord,
  enabled,
  fetch,
}: {
  batchRecord: BatchRecord;
  enabled: boolean;
  fetch: boolean;
}) {
  const server = useServer();
  const dispatch = useDispatch();

  const [diagnosticsEnabled, setDiagnosticsEnabled] = React.useState(false);
  const [currentVersion, setCurrentVersion] = React.useState(0);
  const [currentEvent, setCurrentEvent] = React.useState<
    { item1: string; item2: DebugEvent } | undefined
  >();
  const [result, setResult] = React.useState<BatchRecord | undefined>();
  const [events, setEvents] = React.useState<DebugDto['events'] | undefined>();
  const [confirmed, setConfirm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!enabled || !batchRecord || (!fetch && !confirmed)) return;

    setLoading(true);
    server.getBatchRecordDebugAsync<DebugDto>(batchRecord?.id, currentVersion).then(res => {
      setEvents(res.data.events);
      if (currentVersion > 0) {
        setResult(res.data.batchRecord);
        setCurrentEvent(res.data.events[currentVersion - 1]);
      }
      setLoading(false);
    });
  }, [batchRecord, confirmed, currentVersion, enabled, fetch, server]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      setResult(undefined);
      setCurrentEvent(undefined);
      setCurrentVersion(Number(event.target.value));
    },
    []
  );

  const apply = React.useCallback(
    (payload = result) => {
      dispatch({
        type: FetchBatchRecordActions.SUCCESS,
        payload,
      });
    },
    [dispatch, result]
  );

  return React.useMemo(
    () => ({
      handleChange,
      events,
      result,
      loading,
      currentVersion,
      confirmed,
      dispatch,
      setConfirm,
      diagnosticsEnabled,
      setDiagnosticsEnabled,
      enabled,
      currentEvent,
      apply,
    }),
    [
      handleChange,
      events,
      result,
      loading,
      currentVersion,
      confirmed,
      dispatch,
      diagnosticsEnabled,
      enabled,
      currentEvent,
      apply,
    ]
  );
}
