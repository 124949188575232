import React from 'react';

// libs
import { Switch, Route } from 'react-router-dom';

// components
import StartUp from './components/StartUp';

const path = '/qc-automation';

const routes = () => {
  return (
    <Switch>
      <Route path={path} component={StartUp} />
    </Switch>
  );
};

routes.path = path;
export default routes;
