import React from 'react';

// libs
import { Link } from 'react-router-dom';
import { AppBar, Tab, Tabs, Box, makeStyles, Theme, Fade } from '@material-ui/core';

// interfaces
interface Tab {
  title: string;
  area: string;
  sections: {
    title: string;
    section: string;
  }[];
}
interface ShowSectionsState {
  show: boolean;
  index: null | number;
}
export const tabs: Tab[] = [
  {
    title: 'Testing Input',
    area: 'testing-input',
    sections: [
      {
        title: 'Visual Testing',
        section: 'visual-testing',
      },
      {
        title: 'Functional Testing',
        section: 'functional-testing',
      },
      {
        title: 'Instrument Bias',
        section: 'instrument-bias',
      },
      {
        title: 'Performance Testing',
        section: 'performance-testing',
      },
      {
        title: 'Test Set-Up',
        section: 'test-setup',
      },
    ],
  },
  {
    title: 'Instrument Output',
    area: 'instrument-output',
    sections: [
      {
        title: 'Instrument Summary',
        section: 'instrument-summary',
      },
      {
        title: 'Search',
        section: 'search',
      },
    ],
  },
  {
    title: 'Restricted Access',
    area: 'restricted-access',
    sections: [
      {
        title: 'QA Sign Off',
        section: 'qa-signoff',
      },
      {
        title: 'Super User',
        section: 'superuser',
      },
    ],
  },
];

// styles
const useStyles = makeStyles((theme: Theme) => ({
  navbarContainer: { height: '7.5em', marginTop: '2.7em' },
  mainNavbarItems: {
    padding: 0,
    opacity: 1,
    '&:hover': {
      borderBottom: '5px solid #1CB8DC',
      borderTop: '5px solid transparent',
    },
  },
  secondaryNavbar: {
    borderBottom: '1px solid lightgrey',
    backgroundColor: 'white',
  },
  secondaryNavbarItems: {
    padding: 0,
    fontSize: '0.9em',
    '&:hover': {
      borderTop: '2px solid transparent',
      borderBottom: `2px solid  ${theme.palette.primary.main}`,
    },
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [showSections, setShowSections] = React.useState<ShowSectionsState>({
    show: false,
    index: null,
  });

  return (
    <div
      className={classes.navbarContainer}
      onMouseLeave={() => {
        setShowSections({ show: false, index: null });
      }}
    >
      <AppBar position="static" aria-label="main">
        <Tabs variant="fullWidth" value={false}>
          {tabs.map((t, i) => {
            return (
              <Tab
                className={classes.mainNavbarItems}
                role="navigation"
                label={t.title}
                key={t.area}
                onClick={() => {
                  setShowSections({ show: true, index: i });
                }}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {showSections.show === true && (
        <Fade in>
          <Box data-testid="dropdown" className={classes.secondaryNavbar}>
            <Tabs variant="fullWidth" value={false}>
              {tabs[showSections.index!].sections.map(s => {
                return (
                  <Tab
                    data-testid="sections"
                    className={classes.secondaryNavbarItems}
                    role="navigation"
                    label={s.title}
                    key={s.section}
                    to={`/qc-automation/${tabs[showSections.index!].area}/${s.section}`}
                    component={Link}
                  />
                );
              })}
            </Tabs>
          </Box>
        </Fade>
      )}
    </div>
  );
};

export default Navbar;
