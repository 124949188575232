import React from 'react';

// libs
import { Switch, Route } from 'react-router-dom';

// components
import QASignOff from './components/QASignOff';

const path = '/qc-automation/restricted-access/qa-signoff';

const routes = () => {
  return (
    <Switch>
      <Route path={path} component={QASignOff} />
    </Switch>
  );
};

routes.path = path;
export default routes;
