import React from 'react';

// libs
import { Switch, Route } from 'react-router-dom';

// components
import TestSetup from './components/TestSetup';

const path = '/qc-automation/testing-input/test-setup';

const routes = () => {
  return (
    <Switch>
      <Route path={path} component={TestSetup} />
    </Switch>
  );
};

routes.path = path;
export default routes;
