/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */

import React from 'react';

// libs
import {
  AppBar,
  Zoom,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  FormControlLabel,
  Switch,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  makeStyles,
  Theme,
  TextField,
} from '@material-ui/core';

import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';

// interfaces
interface ControlBank {
  controlBankId: string;
  instruments: {
    serialNumber: string;
    controlBankIndex: string;
    cycleCount: string;
    introductionDt: string;
  }[];
  instrumentsCount: string;
  isApproved: boolean;
  approvedBy: null | string;
  approvedDt: string;
  isReserve: boolean;
  createdDt: string;
  updatedDt: string;
}
interface Props {
  apiResponse: ControlBank[];
}

// styles
const useStyles = makeStyles((theme: Theme) => ({
  container: { height: '30em' },
  appBar: {
    height: '10%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '1em',
  },
  input: {
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #FFF',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #FFF',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '1px solid #FFF',
    },
    width: '25vw',
    '& .MuiInputBase-input:focus': {
      color: 'white',
    },
    transform: 'translateY(0.75em)',
    '@media(max-width: 1140px)': {
      width: '15vw',
    },
  },
  tableBody: {
    '& .MuiTableRow-root:nth-of-type(odd):not(.MuiTableRow-footer)': {
      backgroundColor: '#FFF',
    },
    '& .MuiTableRow-root:nth-of-type(even):not(.MuiTableRow-footer)': {
      backgroundColor: '#FBFBFB',
    },
    '& .MuiTableRow-root:not(.MuiTableRow-footer):hover': {
      backgroundColor: '#BDE3F2',
    },
  },
  tableContainer: {
    borderRadius: 0,
    height: '90%',
  },
  tableContainerWithBtn: {
    borderRadius: 0,
    display: 'flex',
    height: '90%',
    justifyContent: 'center',
  },
  retrieveBanksBtn: {
    '&:hover': { backgroundColor: `${theme.palette.primary.main}` },
    backgroundColor: `${theme.palette.primary.main}`,
    height: '4em',
    color: 'white',
    borderRadius: 0,
    alignSelf: 'center',
  },
  approveBtn: {
    '&:hover': { backgroundColor: `${theme.palette.primary.main}` },
    backgroundColor: `${theme.palette.primary.main}`,
    height: '2em',
    color: 'white',
    borderRadius: 0,
  },
  statusText: {
    color: 'red',
  },
  approvedText: {
    color: 'green',
    paddingTop: '19px',
    paddingBottom: '19px',
  },
  iconBtn: { '&:hover': { backgroundColor: 'transparent' } },
  refreshIcon: {
    color: 'white',
    fontSize: '1.3em',
  },
  appBarTextContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableHeaders: {
    '& .MuiTypography-subtitle1:not(.appBarText2)': {
      lineHeight: '0.5em',
    },
    '@media(max-width: 1186px)': {
      '& .MuiTypography-subtitle1:not(.appBarText2)': {
        fontSize: '1em',
        lineHeight: '1em',
      },
    },
    '& .MuiTableCell-head': {
      backgroundColor: '#FBFBFB',
      borderBottom: '1px solid darkgray',
    },
  },
  appBarText1: {
    paddingRight: '1em',
    '@media(max-width: 1010px)': { fontSize: '1.3em', transform: 'translateY(-1px)' },
    '@media(max-width: 970px)': { fontSize: '1.2em', transform: 'translateY(0px)' },
    '@media(max-width: 895px)': { fontSize: '1em', transform: 'translateY(0px)' },
  },
  appBarText2: {
    transform: 'translateY(4px)',
    '@media(max-width: 1010px)': { fontSize: '1em', transform: 'translateY(3px)' },
    '@media(max-width: 970px)': { fontSize: '0.90em', transform: 'translateY(3px)' },
    '@media(max-width: 895px)': { fontSize: '0.80em', transform: 'translateY(3px)' },
  },
}));

const QAOverviewPanel: React.FC<Props> = ({ apiResponse }) => {
  const [unapprovedBanks, setUnapprovedBanks] = React.useState<ControlBank[]>(apiResponse);

  // collect IDs of approved banks in state after 204 resp and render UI as "approved"
  const [approvedBanksState, setApprovedBanksState] = React.useState<
    ControlBank['controlBankId'][]
  >([]);

  // update state with any new approved banks ^
  const handleApproveClick = (bankId: string) => {
    setApprovedBanksState([...approvedBanksState, bankId]);
  };

  // retrieve banks button
  const [showBanks, setShowBanks] = React.useState(false);

  // format response for table
  const rows = unapprovedBanks.map(bank => {
    const createData = (
      id: string,
      controlbankId: string,
      updatedDt: string,
      instrumentsCount: string,
      isApproved: boolean
    ) => {
      return { id, controlbankId, updatedDt, instrumentsCount, isApproved };
    };
    return createData(
      bank.controlBankId,
      bank.controlBankId,
      bank.updatedDt,
      bank.instrumentsCount,
      bank.isApproved
    );
  });

  const classes = useStyles();

  // mimick auth
  const [authorised, setAuthorised] = React.useState(true);

  // filter results
  const filterBanks = (e: any) => {
    const searched = e.target.value.toLowerCase();
    const filteredBanks = apiResponse.filter(bank => {
      return bank.controlBankId.toLowerCase().includes(searched);
    });
    setUnapprovedBanks(filteredBanks);
  };

  return (
    <div className={classes.container}>
      <FormControlLabel
        control={<Switch defaultChecked />}
        label="Authorised"
        onChange={() => setAuthorised(!authorised)}
      />
      <AppBar position="static" className={classes.appBar}>
        <div className={classes.appBarTextContainer}>
          <Typography variant="h6" className={classes.appBarText1}>
            QA Sign-off
          </Typography>
          <Typography variant="subtitle1" className={classes.appBarText2}>
            View all unapproved banks awaiting sign-off
          </Typography>
        </div>
        <div>
          {showBanks && (
            <>
              <TextField
                variant="standard"
                className={classes.input}
                placeholder="Enter Bank ID"
                onKeyUp={e => filterBanks(e)}
              />
              <IconButton disableRipple className={classes.iconBtn}>
                <RefreshIcon className={classes.refreshIcon} />
              </IconButton>
            </>
          )}
        </div>
      </AppBar>
      {showBanks ? (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Zoom in>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeaders}>
                  <TableCell>
                    <Typography variant="subtitle1">Bank ID</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle1">Last Updated</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle1"># of Instruments</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle1">Status</Typography>
                  </TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {rows.map(row => (
                  <TableRow key={row.controlbankId}>
                    <TableCell scope="row">
                      <Typography variant="subtitle2">{row.controlbankId}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle2">{row.updatedDt}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle2">{row.instrumentsCount}</Typography>
                    </TableCell>
                    {approvedBanksState.includes(row.controlbankId) ? (
                      <>
                        <TableCell align="right" className={classes.approvedText}>
                          <Typography variant="subtitle2"> Approved</Typography>
                        </TableCell>
                        <TableCell align="right" />
                      </>
                    ) : (
                      <>
                        <TableCell align="right" className={classes.statusText}>
                          <Typography variant="subtitle2">
                            {row.isApproved ? 'Approved' : 'Unapproved'}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <Button
                            disableRipple
                            variant="contained"
                            className={classes.approveBtn}
                            onClick={() => {
                              handleApproveClick(row.controlbankId);
                            }}
                          >
                            <Typography variant="subtitle2">Approve</Typography>
                          </Button>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Zoom>
        </TableContainer>
      ) : authorised ? (
        <TableContainer component={Paper} className={classes.tableContainerWithBtn}>
          <Button
            className={classes.retrieveBanksBtn}
            disableRipple
            variant="contained"
            onClick={() => setShowBanks(true)}
          >
            <Typography variant="subtitle2">Retrieve Banks</Typography>
          </Button>
        </TableContainer>
      ) : (
        <TableContainer component={Paper} className={classes.tableContainerWithBtn}>
          <Button
            disabled
            className={classes.retrieveBanksBtn}
            disableRipple
            variant="contained"
            onClick={() => setShowBanks(true)}
          >
            <Typography variant="subtitle2">Not authorised</Typography>
          </Button>
        </TableContainer>
      )}
    </div>
  );
};

export default QAOverviewPanel;
