import React from 'react';
import { Box, Button, ButtonGroup } from '@material-ui/core';
import EventsSelectList from '../EventsSelectList';
import { useDiagnostics } from '../../useDiagnostics';

export default ({ apply, confirmed, result, ...props }: ReturnType<typeof useDiagnostics>) => (
  <>
    {confirmed ? (
      <Box mr={10}>
        <ButtonGroup size="small">
          <EventsSelectList {...props} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              apply();
            }}
            disabled={!result}
          >
            Apply
          </Button>
        </ButtonGroup>
      </Box>
    ) : (
      <></>
    )}
  </>
);
