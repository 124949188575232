import React from 'react';

// libs
import { Route, Switch } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';

// components
import TestingInputRouter from '../../TestingInput/routes';
import InstrumentOutputRouter from '../../InstrumentOutput/routes';
import RestrictedAccessRouter from '../../RestrictedAccess/routes';
import VisualTestingRouter from '../../VisualTesting/routes';
import FunctionalTestingRouter from '../../FunctionalTesting/routes';
import InstrumentBiasRouter from '../../InstrumentBias/routes';
import PerformanceTestingRouter from '../../PerformanceTesting/routes';
import InstrumentSummaryRouter from '../../InstrumentSummary/routes';
import SearchRouter from '../../Search/routes';
import QASignOffRouter from '../../QASignOff/routes';
import SuperUserRouter from '../../SuperUser/routes';
import StartUpRouter from '../../StartUp/routes';
import TestSetupRouter from '../../TestSetup/routes';
import BreadcrumbsBar from './BreadcrumbsBar';
import Navbar from './Navbar';

// styles
const useStyles = makeStyles(() => ({
  container: { borderBottom: '1px solid lightgrey', paddingBottom: '3.8em' },
}));

const Container = () => {
  const classes = useStyles();
  return (
    <>
      <BreadcrumbsBar />
      <Navbar />
      <Grid className={classes.container}>
        <Switch>
          <Route exact path={TestingInputRouter.path} component={TestingInputRouter} />
          <Route exact path={InstrumentOutputRouter.path} component={InstrumentOutputRouter} />
          <Route exact path={RestrictedAccessRouter.path} component={RestrictedAccessRouter} />
          <Route exact path={StartUpRouter.path} component={StartUpRouter} />
          <Route exact path={VisualTestingRouter.path} component={VisualTestingRouter} />
          <Route exact path={InstrumentBiasRouter.path} component={InstrumentBiasRouter} />
          <Route exact path={TestSetupRouter.path} component={TestSetupRouter} />
          <Route exact path={PerformanceTestingRouter.path} component={PerformanceTestingRouter} />
          <Route exact path={FunctionalTestingRouter.path} component={FunctionalTestingRouter} />
          <Route exact path={InstrumentSummaryRouter.path} component={InstrumentSummaryRouter} />
          <Route exact path={SearchRouter.path} component={SearchRouter} />
          <Route exact path={QASignOffRouter.path} component={QASignOffRouter} />
          <Route exact path={SuperUserRouter.path} component={SuperUserRouter} />
        </Switch>
      </Grid>
    </>
  );
};

export default Container;
