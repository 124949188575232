import React from 'react';
import { Select, FormControl, InputLabel } from '@material-ui/core';
import { formatDateTime } from 'modules/Common/utilities';
import { useDiagnostics } from '../../useDiagnostics';

const EventsSelectList = ({
  handleChange,
  currentVersion,
  events,
}: Partial<ReturnType<typeof useDiagnostics>>) => (
  <FormControl variant="filled" size="small">
    <InputLabel>Events</InputLabel>
    <Select onChange={handleChange} value={currentVersion} variant="filled">
      {events?.map(({ item1: name, item2: e }, i) => (
        <option key={e.timestamp} value={i + 1}>
          {i + 1} - {formatDateTime(e.timestamp)} {name}
        </option>
      ))}
    </Select>
  </FormControl>
);

export default EventsSelectList;
