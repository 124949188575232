import {
  LDX_INSTRUMENT_REPAIR_REQUESTS,
  LDX_PILOTLINE_BATCH,
  LDX_PILOTLINE_BATCH_VIEW,
  LDX_INSTRUMENT_QUALITYCONTROL_ADMIN,
  LDX_PILOTLINE_LINES_MODIFY,
  LDX_QC_AUTOMATION,
} from '../domain/permissions';

export default () => [
  {
    key: 'global',
    text: 'Global Data',
    route: null,
    iconKey: 'globe',
    permissions: ['LDX.Global.View', 'LDX.Global.Modify'],
    children: [
      {
        key: 'locations',
        text: 'Locations',
        route: '/global-data/locations',
        iconKey: 'globe',
        permissions: ['LDX.Global.View', 'LDX.Global.Modify'],
      },
      {
        key: 'instrument-versions',
        text: 'Instrument Versions',
        route: '/global-data/instrument-versions',
        iconKey: 'instrumentVersions',
        permissions: ['LDX.Global.View', 'LDX.Global.Modify'],
      },
      {
        key: 'instrument-subsystems',
        text: 'Instrument Subsystems',
        route: '/global-data/instrument-subsystems',
        iconKey: 'instrumentSubsystems',
        permissions: ['LDX.Global.View', 'LDX.Global.Modify'],
      },
      {
        key: 'test-types',
        text: 'Test Types',
        route: '/global-data/test-types',
        iconKey: 'testType',
        permissions: ['LDX.Global.View', 'LDX.Global.Modify'],
      },
      {
        key: 'sample-types',
        text: 'Sample Types',
        route: '/global-data/sample-types',
        iconKey: 'sampleType',
        permissions: ['LDX.Global.View', 'LDX.Global.Modify'],
      },

      {
        key: 'striplines',
        text: 'Strip Lines',
        route: '/global-data/strip-lines',
        iconKey: 'striplines',
        permissions: ['LDX.Global.View', 'LDX.Global.Modify'],
      },
    ],
  },
  {
    key: 'reagents-racker',
    text: 'Reagents Tracker',
    iconKey: 'reagentsTracker',
    permissions: ['LDX.Reagents.Tracker.View'],
    route: '/reagents-tracker',
  },
  {
    key: 'strip-production',
    text: 'Strip Production',
    iconKey: 'stripLotMetadata',
    permissions: ['LDX.StripProduction.View'],
    route: '/strip-production/strip-lot-records',
  },
  {
    key: 'releaseView',
    text: 'Release Review',
    route: null,
    iconKey: 'manufacturing',
    children: [
      {
        key: 'reviewSummary',
        text: 'Review Summary',
        route: '/release-review/summary',
        iconKey: 'reviewSummary',
        permissions: ['LDX.ReleaseReview.View', 'LDX.ReleaseReview.Modify'],
      },
      {
        key: 'stripLots',
        text: 'Strip Lots',
        route: '/release-review/lot-metadata',
        iconKey: 'stripLotMetadata',
        permissions: [
          'LDX.ReleaseReview.RecordLotMetadata',
          'LDX.ReleaseReview.ViewLotMetadata',
          'LDX.ReleaseReview.VerifyLotMetadata',
        ],
      },
      {
        key: 'reagentMetadata',
        text: 'Reagents',
        route: '/release-review/reagent-metadata',
        iconKey: 'reagents',
        permissions: [
          'LDX.ReleaseReview.RecordReagentMetadata',
          'LDX.ReleaseReview.ViewReagentMetadata',
          'LDX.ReleaseReview.VerifyReagentMetadata',
        ],
      },
      {
        key: 'QC Lot Verification',
        text: 'QC Lot Verification',
        route: '/release-review/qc-lot-verification',
        iconKey: 'qcLotVerification',
        permissions: [
          'LDX.ReleaseReview.RecordLotVerification',
          'LDX.ReleaseReview.ViewLotVerification',
          'LDX.ReleaseReview.VerifyLotVerification',
        ],
      },
      {
        key: 'Disposition',
        text: 'Disposition',
        route: '/release-review/disposition-metadata',
        iconKey: 'disposition',
        permissions: [
          'LDX.ReleaseReview.RecordDispositionMetadata',
          'LDX.ReleaseReview.ViewDispositionMetadata',
          'LDX.ReleaseReview.VerifyDispositionMetadata',
        ],
      },
    ],
  },
  {
    key: 'instruments',
    text: 'Instruments',
    route: null,
    iconKey: 'instruments',
    permissions: [LDX_INSTRUMENT_REPAIR_REQUESTS, LDX_INSTRUMENT_QUALITYCONTROL_ADMIN],
    children: [
      {
        key: 'instruments-management',
        text: 'Catalogue',
        route: '/instruments/catalogue',
        iconKey: 'clipboardList',
        permission: LDX_INSTRUMENT_REPAIR_REQUESTS,
      },
      {
        key: 'instruments-repair',
        text: 'Repair Requests',
        route: '/instrument-repair-requests',
        iconKey: 'tools',
        permission: LDX_INSTRUMENT_REPAIR_REQUESTS,
      },
      {
        key: 'instruments-quality-control',
        text: 'Quality Control',
        route: '/instruments-quality-control',
        iconKey: 'progressCheck',
        permission: LDX_INSTRUMENT_QUALITYCONTROL_ADMIN,
      },
    ],
  },
  {
    key: 'qc-automation',
    text: 'QC Automation',
    iconKey: 'progressCheck',
    permissions: LDX_QC_AUTOMATION,
    route: '/qc-automation',
  },
  {
    key: 'pilot',
    text: 'Pilot Line',
    route: null,
    iconKey: 'stove',
    permission: LDX_PILOTLINE_BATCH,
    children: [
      {
        key: 'pilot-batchrecords',
        text: 'Batch Records',
        route: '/pilot-line/batch-records',
        iconKey: 'formatListChecks',
        permission: LDX_PILOTLINE_BATCH_VIEW,
      },
      {
        key: 'pilot-lines',
        text: 'Lines',
        route: '/pilot-line/lines',
        iconKey: 'pilotLines',
        permission: LDX_PILOTLINE_LINES_MODIFY,
      },
    ],
  },
];
