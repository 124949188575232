// THIS ROUTE WILL DEFAULT TO INSTRUMENT SUMMARY SCREEN

import React from 'react';

// libs
import { Switch, Route, Redirect } from 'react-router-dom';

// components
import InstrumentSummary from '../InstrumentSummary/components/InstrumentSummary';

const path = '/qc-automation/instrument-output';
const redirectRoute = '/qc-automation/instrument-output/instrument-summary';

const routes = () => {
  return (
    <Switch>
      <Route path={path}>
        <Redirect to={redirectRoute} component={InstrumentSummary} />
      </Route>
    </Switch>
  );
};

routes.path = path;
export default routes;
