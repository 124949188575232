import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { rootPath } from 'modules/StripProduction/constants';
import Home from 'modules/StripProduction/Containers/Home';

const routes = () => {
  return (
    <>
      <Switch>
        <Route path={rootPath}>
          <Home />
        </Route>
      </Switch>
    </>
  );
};

routes.path = '/strip-production';

export { entityPath } from './StripLotRecord/constants';
export default routes;
