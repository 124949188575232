/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Select, Grid, FormControl, Box, Container, InputLabel, Button } from '@material-ui/core';
import ReactJson from 'react-json-view';
import { formatDateTime } from 'modules/Common/utilities';
import { FetchBatchRecordActions } from 'modules/Pilot/BatchRecord/actions';
import { useDiagnostics } from '../../useDiagnostics';

type DiagnosticsPanelProps = {} & ReturnType<typeof useDiagnostics>;

const EventsSelectList = ({
  handleChange,
  currentVersion,
  events,
}: Partial<ReturnType<typeof useDiagnostics>>) => (
  <FormControl>
    <InputLabel>Events</InputLabel>
    <Select onChange={handleChange} value={currentVersion} variant="filled">
      {events?.map(({ item1: name, item2: e }, i) => (
        <option key={e.timestamp} value={i + 1}>
          {i + 1} - {formatDateTime(e.timestamp)} {name}
        </option>
      ))}
    </Select>
  </FormControl>
);

export default ({
  handleChange,
  events,
  result,
  loading,
  currentVersion,
  confirmed,
  setConfirm,
  currentEvent,
  apply,
}: DiagnosticsPanelProps) => {
  if (!events && !result) return <Container>Loading....</Container>;

  return (
    <>
      <Box ml={5} mr={5}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <EventsSelectList
              handleChange={handleChange}
              currentVersion={currentVersion}
              events={events}
            />
          </Grid>
          {result && (
            <Grid item xs={12}>
              {!!result && !confirmed && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setConfirm(true);
                  }}
                >
                  Enable Time Travel
                </Button>
              )}
              {!!result && confirmed && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    apply();
                  }}
                >
                  Apply
                </Button>
              )}
            </Grid>
          )}
          <Grid item xs={6}>
            {!!result && <ReactJson src={result} />}
            {loading && <>Loading...</>}
          </Grid>

          <Grid item xs={6}>
            {!!currentEvent && <ReactJson src={currentEvent?.item2} />}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
