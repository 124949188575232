// THIS ROUTE WILL DEFAULT TO INSTRUMENT BIAS SCREEN

import React from 'react';

// libs
import { Switch, Route, Redirect } from 'react-router-dom';

// components
import InstrumentBias from '../InstrumentBias/components/InstrumentBias';

const path = '/qc-automation/testing-input';
const redirectRoute = '/qc-automation/testing-input/instrument-bias';

const routes = () => {
  return (
    <Switch>
      <Route path={path}>
        <Redirect to={redirectRoute} component={InstrumentBias} />
      </Route>
    </Switch>
  );
};

routes.path = path;
export default routes;
